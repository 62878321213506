<script>
export default {
  name: 'DialIndex',
  props: {
    mode: {
      type: String,
      default() {
        const setting = this.$store.getters['kiosk/setting']
        return setting.loginType || 'pin'
      }
    }
  },
  mounted() {
    this.$router.push(this.mode)
  },
  render() {
    return null
  }
}
</script>
